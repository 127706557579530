export const environment = {
  production: false,
  acceptance: false,
  test: false,
  dev: true,
  local: false,
  api: 'https://api.dev.rallygo.eu/api',
  images: 'https://images.acc.rallygo.eu',
  web: 'https://web.dev.rallygo.eu',
  services: {
    core: `https://core.api.dev.rallygo.eu/v3`,
    payments: `https://payments.api.dev.rallygo.eu/v3`,
  }
};
